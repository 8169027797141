<template>
  <div class="organization_dashboard">
    <NavBar />
    <Breadcrumb :breadcrumb="state.listBreadcrumb" />
    <div class="organization_dashboard__info">
      <div class="information_area" v-if="!loadingCheckActiveTeamSelect">
        <div class="information_area__block">
          <div class="information_area__block__news">
            <span class="label_top">
              お知らせ
            </span>
          </div>
          <div class="information_area__block__contents">
            <div v-if="state.round_name && state.period_time?.start_at && state.period_time?.end_at" class="information_area__block__contents__main">
              <span>
                現在{{ state.round_name }}のチーム選択期間です。
                期間内に新規プロジェクト作成、編集、停止、チームとのマッチングを行うようにしてください。
              </span>
              <span class="time_limit">
                期間:{{ state.period_time?.start_at }} 〜 {{ state.period_time?.end_at }}
              </span>
            </div>
            <div v-else>
              現在はチーム選択期間外です。
              新規プロジェクト作成、編集、停止、チームとのマッチング操作は行えません。<br>
              <span v-if="state.next_period?.start_at === '未定'" class="time_limit">
                次回のチーム選択期間は未定です。
              </span>
              <span v-else class="time_limit">
                次回のチーム選択期間:{{ state.next_period?.start_at }} 〜 {{ state.next_period?.end_at}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="organization_dashboard__container container">
      <div class="organization_dashboard__container__left left-side">
        <OrganizationProfile :accountId="state.accountId"/>
      </div>
      <div class="organization_dashboard__container__right main-area">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/views/organization/dashboard/Dashboard.ts" scoped>
</script>

<style lang="scss" src="@/presentation/views/organization/dashboard/Dashboard.scss" scoped>
</style>
