import { reactive, defineComponent, onMounted, computed } from "vue";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import NavBar from "@/presentation/components/navigation/Navigation.vue";
import OrganizationProfile from "@/presentation/components/organization-profile/OrganizationProfile.vue";
import DashboardAlert from "@/presentation/components/dashboard-alert/DashboardAlert.vue";
import DIContainer from "@/core/DIContainer";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import CommonService from "@/services/CommonService";
import { useStore } from "vuex";
import Constant from '@/commons/Constant';
import RoundInteractor from "@/domain/usecases/RoundInteractor";
export default defineComponent({
    name: "OrganizationDashboard",
    components: {
        Breadcrumb: Breadcrumb,
        NavBar: NavBar,
        OrganizationProfile: OrganizationProfile,
        DashboardAlert: DashboardAlert,
    },
    setup: function () {
        var store = useStore();
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        var roundInteractor = DIContainer.instance.get(RoundInteractor);
        var state = reactive({
            listBreadcrumb: [
                {
                    route: "",
                    name: "ダッシュボード"
                }
            ],
            showDashboardAlert: true,
            organizationRating: null,
            accountId: null,
            round_name: null,
            period_time: null,
            next_period: null,
            loadingCheckActiveTeamSelect: true,
        });
        var loadingCheckActiveTeamSelect = computed(function () { return state.loadingCheckActiveTeamSelect; });
        function checkTeamSelectPeriod() {
            return roundInteractor.getRoundTeamSelectPeriod().then(function (response) {
                state.round_name = response.round_name;
                state.period_time = response.period_time;
                state.next_period = response.next_period ? response.next_period : { start_at: "未定", end_at: "未定" };
                state.loadingCheckActiveTeamSelect = false;
            });
        }
        onMounted(function () {
            store.commit("checkReadGuideScreen", Constant.GUIDE_OR_DASHBOARD);
            var user = CommonService.getCookie("user");
            var user_info = user ? JSON.parse(decodeURIComponent(user)) : null;
            var id = user_info && user_info['account_id'] ? user_info['account_id'] : null;
            state.accountId = id;
            checkTeamSelectPeriod();
        });
        return {
            state: state,
            loadingCheckActiveTeamSelect: loadingCheckActiveTeamSelect
        };
    }
});
